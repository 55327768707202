const myMixin = {
  data() {
    return {
      Base_offset: 0,
      bodyHeight: 0,
    };
  },
  mounted() {
    window.addEventListener('resize', this.reSizeEvent);
    this.reSizeEvent();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.reSizeEvent);
  },
  methods: {
    reSizeEvent() {
      this.bodyHeight = document.body.offsetHeight;
    },
  },
  computed: {
    countHeight() {
      return this.bodyHeight - this.Base_offset;
    },
  },
};

export default myMixin;
