<template>
  <div id="SellOrder">
    <template v-if="isMobile">
      <SellOrderMobile> </SellOrderMobile>
    </template>
    <template v-else>
      <SellOrderPC> </SellOrderPC>
    </template>
  </div>
</template>

<script>
  import SellOrderPC from './SellOrderPC.vue';
  import SellOrderMobile from './SellOrderMobile.vue';
  export default {
    name: 'SellOrder',
    components: {
      SellOrderPC,
      SellOrderMobile,
    },
    computed: {
      isMobile() {
        return this.$store.state.isMobile;
      },
    },
  };
</script>

<style lang="scss">
  @import '@/views/Console/Style/TopCardStyle.scss';
  #SellOrder {
    height: 100%;
    .ruleForm {
      .el-form-item {
        &:last-child {
          margin-top: 35px;
          margin-bottom: 0px;
        }
        .el-form-item__content {
          margin: 0 !important;

          display: flex;
          justify-content: center;
        }
      }
    }
  }
</style>
