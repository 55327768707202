<template>
  <div id="SellOrderMobile" ref="SellOrderMobile">
    <div class="titleBlock">
      <div class="row">
        <div class="TitleText"> 搜寻分类：</div>
        <el-select v-model="searchModel">
          <el-option
            v-for="item in searchOption"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <el-input
        class="searchInput"
        style="margin-bottom: 15px"
        v-model="searchInput"
        :placeholder="placeholderJudge"
      >
      </el-input>
      <div class="TitleText"> 订单区间： </div>
      <div class="row">
        <el-date-picker
          size="mini"
          prefix-icon="none"
          v-model="pickTimeRange.start"
          type="datetime"
          placeholder="选择日期时间"
          align="right"
          :clearable="false"
        >
        </el-date-picker>
        <div class="to">&nbsp;~&nbsp; </div>
        <el-date-picker
          size="mini"
          prefix-icon="none"
          v-model="pickTimeRange.end"
          type="datetime"
          placeholder="选择日期时间"
          align="right"
          :clearable="false"
        >
        </el-date-picker>
      </div>
      <div class="row">
        <div class="SearchButton" @click="initAPI(true)"> 查询 </div>
      </div>

      <div class="splitLine"> </div>

      <div class="CardItem" v-for="(it, index) in tableData" :key="index">
        <div class="CardContainer">
          <div class="CardDataRow">
            <div class="CardTitle"> 订单时间 </div>
            <div class="CardVal"> {{ it.trade_at }}</div>
            <div class="CardLine"></div>
            <div class="CardTitle"> 订单编号 </div>
            <div class="CardVal"> {{ it.sn }}</div>
            <div class="CardLine"></div>
            <div class="CardTitle"> 银行卡号 </div>
            <div class="CardVal"> {{ it.bm_card_num }}</div>
            <div class="CardLine"></div>
            <div class="CardTitle"> 购买用户 </div>
            <div class="CardVal"> {{ it.buyer_name }}</div>
            <div class="CardLine"></div>
            <div class="CardTitle"> 购买物品 </div>
            <div class="CardVal"> {{ it.cart[0].name }}</div>
            <div class="CardLine"></div>
            <template v-if="isCommodity">
              <div class="CardTitle">采购金额 </div>
              <div class="CardVal"> ￥{{ it.purchase_total }}</div>
              <div class="CardLine"></div>
              <div class="CardTitle">代购费用 </div>
              <div class="CardVal"> ￥{{ it.fee }}</div>
              <div class="CardLine"></div>

            </template>
            <div class="CardTitle"> {{ isCommodity ? '用戶' : '' }}购买金额 </div>
            <div class="CardVal"> ￥{{ it.cart[0].price }}</div>
            <div class="CardLine"></div>
            <template v-if="!isCommodity">
              <div class="CardTitle"> 运送状态 </div>
              <div class="CardVal">
                <span v-if="it.deliver_state === 'arrived'">已交货结单</span>
                <span v-else-if="it.deliver_state === 'prepared'">收到订单捡货中</span>
                <span v-else-if="it.deliver_state === 'delivering'">
                  {{
                    `送货中：预估剩${Math.ceil(
                      (new Date(it.delivering_end_at * 1000).getTime() - Date.now()) / 86400000
                    )}天到货`
                  }}
                </span>
                <span v-else-if="it.deliver_state === 'none'">none</span>
              </div>
            </template>
            <template v-else>
              <div class="CardTitle"> 运送状态 </div>
              <div class="CardVal">
                {{ it.purchase_state === 'purchasing' ? '采购中' : '采购完成' }}
              </div>
            </template>
          </div>
        </div>
      </div>
      <div class="LastTip" v-if="isNoMoreData"> 已无更多资料 </div>
    </div>
  </div>
</template>

<script>
import mixin from './SellOrderMixin';
export default {
  mixins: [mixin],
  name: 'SellOrderMobile',
  data() {
    return {
      isCommodity: false
    }
  },
  computed: {
    isNoMoreData() {
      return this.pageData.currentPage === this.pageData.totalPages;
    },
  },
  watch: {
    $route: {
      handler() {
        this.initParamsFetch()
      }
    }
  },
  created() {
    this.initParamsFetch()
  },
  mounted() {
    this.$refs.SellOrderMobile.addEventListener('scroll', (event) => {
      const element = event.target;
      if (Math.floor(element.scrollHeight - element.scrollTop) <= element.clientHeight) {
        if (this.pageData.currentPage < this.pageData.totalPages) {
          this.pageData.currentPage++;
          this.initAPI();
        }
      }
    });
  },
  methods: {
    initParamsFetch() {
      this.isCommodity = this.$route.name === 'ConsoleOrderManagement_CommodityPurchase' ? true : false
      this.defaultRequestParams()
      this.tableData = [] // 有点奇怪
      if (this.$route.params?.uid) {
        this.searchInput = this.$route.params?.uid;
        this.searchModel = 'account_id';
      }
      this.initAPI();
    },
    initAPI(isSearchChange = false) {
      if (this.pickTimeRange.start === null || this.pickTimeRange.end === null) {
        this.$MSG.error('日期区间尚未选取完成');
        return;
      }
      if (isSearchChange) {
        this.pageData.currentPage = 1;
      }
      this.$store.commit('setLoading', true);

      const pickTimeRange = Object.assign({}, this.pickTimeRange);
      pickTimeRange.start = this.$lib.timeFormat(pickTimeRange.start);
      pickTimeRange.end = this.$lib.timeFormat(pickTimeRange.end);

      this.$store
        .dispatch('admin/order/getOrders', {
          page: this.pageData.currentPage,
          pageSize: this.pageData.PAGE_SIZE,
          starttime: pickTimeRange.start,
          endtime: pickTimeRange.end,
          search: this.searchInput,
          search_type: this.searchModel,
        })
        .then((res) => {
          this.pageData.currentPage = res.currentPage;
          this.pageData.totalPages = res.totalPages;
          this.pageData.count = res.count;
          if (isSearchChange) {
            this.tableData.length = 0;
          }
          this.tableData.push(...res.data);
        })
        .catch(this.$autoCatch)
        .finally(() => {
          this.$store.commit('setLoading', false);
        });
    },
  },
};
</script>

<style lang="scss">
  #SellOrderMobile {
    input {
      padding: 0px 5px;
      text-align: center;
    }
    .el-radio.is-bordered {
      padding: 9px 20px 0 10px;
      height: 34px;
    }
  }
</style>

<style lang="scss" scoped>
  #SellOrderMobile {
    height: 100%;
    overflow-y: auto;
    padding-right: 5px;
    &::-webkit-scrollbar {
      -webkit-appearance: none;
    }

    &::-webkit-scrollbar:vertical {
      width: 6px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 8px;
      background-color: rgba(0, 0, 0, 0.3);
    }
    .titleBlock {
      .row {
        display: flex;
        align-items: center;
        width: 100%;
        margin: 10px 0;
        margin-bottom: 12px;
        .TitleText {
          font-size: 16px;
          margin-right: 3%;
          width: fit-content;
          white-space: nowrap;
          color: #6e6e6e;
        }
        .radioRow {
          display: flex;
          width: 70%;
          .el-radio {
            margin-right: 2%;
            max-width: 130px;
            width: 50%;
            display: flex;
            justify-content: center;
          }
        }
        .SearchButton {
          color: white;
          padding: 10px 0;
          background-color: #85ce61;
          width: 100%;
          text-align: center;
          border-radius: 3px;
          font-size: 14px;
        }
      }
    }
    .splitLine {
      width: 100%;
      height: 1px;
      margin: 20px 0;
      background-color: #cdd0d8;
    }

    .CardItem {
      border: 1px solid #cdd0d8;
      color: #6e6e6e;
      border-radius: 2px;
      font-size: 14px;
      margin-bottom: 10px;
      .CardContainer {
        padding: 16px 30px;
        .CardDataRow {
          display: flex;
          flex-wrap: wrap;
          width: 100%;
          .CardTitle {
            width: 100px;
            display: flex;
            align-items: center;
          }
          .CardVal {
            width: calc(100% - 100px);
            color: #818080;
            line-height: 18px;
          }
          .CardLine {
            width: 100%;
            height: 1px;
            background-color: rgba(128, 128, 128, 0.189);
            margin: 10px 0;
            transform: scaleX(1.05);
          }
          .CardValRow {
            display: flex;
            .CardBtn {
              padding: 8px 10px;
              color: #818080;
              border: 1px solid #cdd0d8;
              border-radius: 3px;
              margin-left: 10px;
            }
          }
        }
      }
    }
    .LastTip {
      font-size: 18px;
      text-align: center;
      font-weight: bold;
      padding: 25px 0;
      color: #6e6e6e;
    }
  }
</style>
