<template>
  <div id="SellOrderPC">
    <div shadow="never" class="TopCardContainer">
      <el-card shadow="never" class="SearchCardContainer">
        <div class="row" style="margin-bottom: 10px">
          <div class="FilterTitleText"> 搜寻分类 </div>

          <el-select v-model="searchModel">
            <el-option
              v-for="item in searchOption"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>

        <el-input class="searchInput" v-model="searchInput" :placeholder="placeholderJudge">
          <el-button slot="append" icon="el-icon-search" @click="searchClickHandler"></el-button>
        </el-input>
      </el-card>

      <el-card shadow="never" class="FilterCardContainer">
        <div class="FilterLeftBlock"> 订单区间 </div>
        <div class="FilterRightBlock">
          <div class="FilterRightTopBlock">
            <div class="titleText">从 :</div>
            <el-date-picker
              v-model="pickTimeRange.start"
              type="datetime"
              placeholder="选择日期时间"
              align="right"
            >
            </el-date-picker>
          </div>
          <div class="FilterRightBottomBlock">
            <div class="titleText">到 :</div>
            <el-date-picker
              v-model="pickTimeRange.end"
              type="datetime"
              placeholder="选择日期时间"
              align="right"
            >
            </el-date-picker>
            <el-button type="success" @click="searchClickHandler">查询</el-button>
          </div>
        </div>

        <div class="printerBtn" @click="goPrint">
          <i class="el-icon-printer"></i>
          打印订单
        </div>
      </el-card>
    </div>

    <div class="BottomContainer">
      <el-table
        tooltip-effect="dark"
        style="width: 100%"
        :data="tableData"
        :max-height="countHeight"
      >
        <el-table-column
          align="center"
          prop="trade_at"
          label="订单时间"
          width="180"
        ></el-table-column>
        <el-table-column align="center" prop="sn" label="订单编号" width="180"></el-table-column>
        <el-table-column
          align="center"
          prop="bm_card_num"
          label="银行卡号"
          width="220"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="buyer_name"
          label="购买用户"
          width="150"
        ></el-table-column>

        <el-table-column align="center" prop="cart[0].name" label="购买物品"></el-table-column>

        <template v-if="isCommodity">
          <el-table-column align="center" label="采购金额">
            <template slot-scope="scope">
              <span style="letter-spacing: 1.2px; white-space: nowrap"
                >￥{{ scope.row.purchase_total }}</span
              >
            </template>
          </el-table-column>
          <el-table-column align="center" label="代购费用">
            <template slot-scope="scope">
              <span style="letter-spacing: 1.2px; white-space: nowrap"
                >￥{{ scope.row.fee }}</span
              >
            </template>
          </el-table-column>
        </template>

        <el-table-column align="center" :label="`${isCommodity ? '用户' : ''}购买金额`">
          <template slot-scope="scope">
            <span style="letter-spacing: 1.2px; white-space: nowrap"
              >￥{{ scope.row.cart[0].price }}</span
            >
          </template>
        </el-table-column>

        <el-table-column align="center" v-if="!isCommodity" label="运送状态">
          <template slot-scope="scope">
            <span v-if="scope.row.deliver_state === 'arrived'">已交货结单</span>
            <span v-else-if="scope.row.deliver_state === 'prepared'">收到订单捡货中</span>
            <span v-else-if="scope.row.deliver_state === 'delivering'">
              {{
                `送货中：预估剩${Math.ceil(
                  (new Date(scope.row.delivering_end_at * 1000).getTime() - Date.now()) / 86400000
                )}天到货`
              }}
            </span>
            <span v-else-if="scope.row.deliver_state === 'none'">none</span>
          </template>
        </el-table-column>

        <el-table-column align="center" v-else label="采购状态">
          <template slot-scope="scope">
            {{ scope.row.purchase_state === 'purchasing' ? '采购中' : '采购完成' }}
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        layout="sizes,prev, pager, next, jumper"
        :current-page.sync="pageData.currentPage"
        :page-size="pageData.PAGE_SIZE"
        :page-count="pageData.totalPages"
        :total="pageData.count"
        @size-change="pageSizeChange"
        @current-change="initAPI"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import mixin from './SellOrderMixin';
import AutoCountTableHeight from '@/views/Console/Base/AutoCountTableHeight.js';
import { objToUrlQuery } from '@/utils/axiosTools';

export default {
  mixins: [AutoCountTableHeight, mixin],
  name: 'SellOrder',
  data() {
    return {
      isCommodity: false
    }
  },
  watch: {
    $route: {
      handler() {
        this.initParamsFetch()
      }
    }
  },
  created() {
    this.initParamsFetch()
  },
  methods: {
    initParamsFetch() {
      this.isCommodity = this.$route.name === 'ConsoleOrderManagement_CommodityPurchase' ? true : false
      this.defaultRequestParams()
      if (this.$route.params?.uid) {
        this.searchInput = this.$route.params?.uid;
        this.searchModel = 'account_id';
      }
      this.initAPI()
    },
    goPrint() {
      const pickTimeRange = Object.assign({}, this.pickTimeRange);
      pickTimeRange.start = this.$lib.timeFormat(pickTimeRange.start);
      pickTimeRange.end = this.$lib.timeFormat(pickTimeRange.end);
      const queryData = {
        page: this.pageData.currentPage,
        pageSize: this.pageData.PAGE_SIZE,
        starttime: pickTimeRange.start,
        endtime: pickTimeRange.end,
        search: this.searchInput,
        search_type: this.searchModel,
      };
      if (process.env.NODE_ENV === 'development') {
        window.open(`http://125.daigo.com/web/reports/order_pdf${objToUrlQuery(queryData)}`);
      } else {
        window.open(`/web/reports/order_pdf${objToUrlQuery(queryData)}`);
      }
    },
  },
};
</script>

<style lang="scss">
  @import '@/views/Console/Style/TopCardStyle.scss';
</style>

<style lang="scss" scoped>
  #SellOrderPC {
    position: relative;
    .printerBtn {
      position: absolute;
      right: 40px;
      top: -47.5px;
      font-size: 15px;
      cursor: pointer;
    }
  }
</style>
