
const SellOrderMixin = {
  data() {
    const endDate = new Date();
    endDate.setTime(endDate.getTime() - 3600 * 1000 * 24 * 7);
    return {
      Base_offset: 280,
      searchInput: '',
      tableData: [],
      pickTimeRange: {
        start: endDate,
        end: new Date(),
      },
      defaultPickTimeValue: {
        start: this.$lib.timeFormat(endDate),
        end: this.$lib.timeFormat(Date.now()),
      },
      pageData: {
        currentPage: 1,
        totalPages: 1,
        count: 0,
        PAGE_SIZE: 10,
      },
      searchModel: '',
      searchOption: [],
    };
  },
  computed: {
    isSupport() {
      return this.$store.state.admin.userType === 'supporter';
    },
    placeholderJudge() {
      if (this.searchModel === '') {
        return '请选择搜寻分类';
      } else if (this.searchModel === 'account_id') {
        return '输入查找用户 ID';
      } else if (this.searchModel === 'product_name') {
        return '输入查找商品';
      } else if (this.searchModel === 'bm_card_num') {
        return '输入查找的卡号13～19码';
      } else if (this.searchModel === 'sn') {
        return '输入订单编号';
      } else {
        return '';
      }
    },
  },
  created() {
    if (this.isSupport) {
      this.searchOption = [
        {
          label: '请选择',
          value: '',
        },
        {
          label: '卖家',
          value: 'account_id',
        },
        {
          label: '购买用户',
          value: 'buyer_name',
        },
        {
          label: '商品',
          value: 'product_name',
        },
        {
          label: '银行卡号',
          value: 'bm_card_num',
        },
        {
          label: '订单编号',
          value: 'sn',
        }
      ];
    } else {
      this.searchOption = [
        {
          label: '请选择',
          value: '',
        },
        {
          label: '商品',
          value: 'product_name',
        },
        {
          label: '银行卡号',
          value: 'bm_card_num',
        },
        {
          label: '订单编号',
          value: 'sn',
        },
      ];
    }
  },
  methods: {
    initAPI() {
      if (this.pickTimeRange.start === null || this.pickTimeRange.end === null) {
        this.$MSG.error('日期区间尚未选取完成');
        return;
      }
      this.$store.commit('setLoading', true);

      const pickTimeRange = Object.assign({}, this.pickTimeRange);
      pickTimeRange.start = this.$lib.timeFormat(pickTimeRange.start);
      pickTimeRange.end = this.$lib.timeFormat(pickTimeRange.end);

      this.$store
        .dispatch('admin/order/getOrders', {
          page: this.pageData.currentPage,
          pageSize: this.pageData.PAGE_SIZE,
          starttime: pickTimeRange.start,
          endtime: pickTimeRange.end,
          search: this.searchInput,
          search_type: this.searchModel,
        })
        .then((res) => {
          this.pageData.currentPage = res.currentPage;
          this.pageData.totalPages = res.totalPages;
          this.pageData.count = res.count;
          this.tableData = res.data;
        })
        .catch(this.$autoCatch)
        .finally(() => {
          this.$store.commit('setLoading', false);
        });
    },
    pageSizeChange(val) {
      this.pageData.PAGE_SIZE = val;
      this.initAPI();
    },
    searchClickHandler() {
      this.pageData.currentPage = 1;
      this.initAPI();
    },
    defaultRequestParams() {
      const endDate = new Date()
      endDate.setTime(endDate.getTime() - 3600 * 1000 * 24 * 7)

      this.searchModel = ''
      this.searchInput = ''
      this.pickTimeRange = {
        start: endDate,
        end: new Date(),
      },
      this.defaultPickTimeValue = {
        start: this.$lib.timeFormat(endDate),
        end: this.$lib.timeFormat(Date.now())
      }
      this.pageData = {
        currentPage: 1,
        totalPages: 1,
        count: 0,
        PAGE_SIZE: 10
      }
    }
  },
};

export default SellOrderMixin;
